import classNames from 'classnames';
import Img from 'gatsby-image';
import _has from 'lodash/has';
import PropTypes from 'prop-types';
import React from 'react';

import './member-header.scss';

/**
 * Component for rendering a banner.
 */
const MemberHeader = (props) => {
    let classes = props.classes || [];
    if (props.introduction && props.introduction.processed) {
        classes.push('has-introduction');
    }
    if (_has(props, 'logo.r.image')) {
        classes.push('has-logo');
    }
    classes = classes.concat(['content-header', 'content-header--member']);
    let country_classes = [
        'content-header--name-en',
        'country--' + props.country,
    ];
    return (
        <div className={classNames(classes)}>
            <div className="content-header--top">
                <div className="container">
                    <div className="d-flex align-items-start content-header--top-wrapper">
                        {_has(props, 'logo.r.image') && (
                            <div className="content-header--logo">
                                <Img
                                    fluid={
                                        props.logo.r.image.localFile
                                            .childImageSharp.fluid
                                    }
                                />
                            </div>
                        )}
                        <div className="content-header--name">
                            <h1>{props.name}</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="content-header--bottom">
                    <div className="country-flag">
                        <div
                            className={classNames([
                                'country-flag--image',
                                'country-flag--' + props.country,
                            ])}></div>
                    </div>
                    {props.name_en && props.showName && (
                        <div className={classNames(country_classes)}>
                            {props.name_en}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

MemberHeader.propTypes = {
    logo: PropTypes.object,
    name: PropTypes.string.isRequired,
    name_en: PropTypes.string,
    country: PropTypes.string,
    classes: PropTypes.array,
    showName: PropTypes.bool,
};

export default MemberHeader;
