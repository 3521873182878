import PropTypes from 'prop-types';
import React from 'react';

/**
 * Component for rendering a phone number.
 */
const FieldPhone = (props) => {
    return (
        <div className="phone-field">
            {props.label}: <a href={'tel:' + props.number}>{props.number}</a>
        </div>
    );
};

FieldPhone.propTypes = {
    number: PropTypes.string,
    label: PropTypes.string,
};

export default FieldPhone;
