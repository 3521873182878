import PropTypes from 'prop-types';
import React from 'react';

import productTypes from '../../../utils/ProductUtils';
import FieldLink from '../../field/FieldLink/FieldLink';
import './member-products.scss';

/**
 * Component for rendering the products a member can award.
 */
const MemberProducts = (props) => {
    return (
        <>
            {productTypes(props.member).map((stat) => {
                const statsKey = `stats-${stat.type}-${props.member.key}`
                return (
                    <div key={statsKey} className="member-product">
                        <img
                            src={stat.logo}
                            alt={stat.singular + ' logo'}
                            className="member-product__logo"
                        />
                        <div className="member-product__link">
                            <i className="bi bi-arrow-right-short"></i>
                            { stat.registrations > 0 && (
                                <FieldLink
                                    link={{
                                        uri:
                                            '/valuers?type=' +
                                            stat.type +
                                            '&member=' +
                                            props.member.key,
                                    }}>
                                    See the {stat.registrations} recognised
                                    valuers awarded by this member
                                </FieldLink>
                            )}
                            { stat.registrations <= 0 && (
                                <FieldLink
                                    link={{
                                        uri:
                                            '/valuers?type=' +
                                            stat.type,
                                    }}>
                                    No recognised valuers awarded by this member yet
                                </FieldLink>
                            )}
                        </div>
                    </div>
                );
            })}
        </>
    );
};

MemberProducts.propTypes = {
    member: PropTypes.object,
};

export default MemberProducts;
