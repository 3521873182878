import { graphql } from 'gatsby';
import React from 'react';

import ContentBreadcrumb from '../content/ContentBreadcrumb/ContentBreadcrumb';
import ContentHeader from '../content/ContentHeader/ContentHeader';
import MemberHeader from '../content/MemberHeader/MemberHeader';
import MemberProducts from '../content/MemberProducts/MemberProducts';
import MemberRepresentatives from '../content/MemberRepresentative/MemberRepresentatives';
import TegovaCountry from '../content/TegovaCountry/TegovaCountry';
import FieldAddress from '../field/FieldAddress/FieldAddress';
import FieldEmail from '../field/FieldEmail/FieldEmail';
import FieldLinks from '../field/FieldLinks/FieldLinks';
import FieldPhone from '../field/FieldPhone/FieldPhone';
import FieldSections from '../field/FieldSections/FieldSections';
import Layout from '../layout/layout';
import Seo from '../layout/seo';
import './member-association.scss';
import './page.scss';

const MemberAssociationPage = ({ data, pageContext }) => {
    const page = data.member_page;
    const country_members = data.country_members.edges;
    const {
        breadcrumb: { crumbs },
    } = pageContext;

    return (
        <Layout>
            <ContentBreadcrumb crumbs={crumbs} label={page.title} />
            {(page.r.association && (
                <MemberHeader
                    {...page.r.association}
                    logo={page.r.logo}
                    country={page.r.association.country}
                    showName={page.show_name !== false}
                />
            )) || <ContentHeader title={page.title} />}
            <div className="page__content">
                <Seo title={page.title} />
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h2>Coordinates</h2>
                            {page.address && <FieldAddress {...page.address} />}
                            {page.phone && (
                                <FieldPhone number={page.phone} label="Tel" />
                            )}
                            {page.fax && (
                                <FieldPhone number={page.fax} label="Fax" />
                            )}
                            {page.email && <FieldEmail email={page.email} />}
                            {page.links && <FieldLinks links={page.links} />}
                            {page.structure && page.structure.processed && (
                                <div
                                    className="member--structure"
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            '<h2>Structure</h2>' +
                                            page.structure.processed,
                                    }}
                                />
                            )}
                            {page.intro && (
                                <div className="member--intro">
                                    {page.intro}
                                </div>
                            )}
                            <MemberRepresentatives
                                representatives={page.r.representatives}
                            />
                            {page.r && page.r.sections && (
                                <FieldSections sections={page.r.sections} />
                            )}
                        </div>
                        <div className="col-md-4">
                            <MemberProducts member={page.r.association} />
                            {page.r.tegova_country && (
                                <TegovaCountry
                                    members={country_members}
                                    name={page.r.tegova_country.name}
                                    description={
                                        page.r.tegova_country.description
                                            ? page.r.tegova_country.description
                                                  .processed
                                            : ''
                                    }
                                    logo={
                                        page.r.tegova_country.r.logo
                                            ? page.r.tegova_country.r.logo.r
                                                  .image
                                            : null
                                    }
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default MemberAssociationPage;

export const query = graphql`
    query ($id: String!, $country_id: String) {
        member_page: nodeMemberAssociationPage(id: { eq: $id }) {
            ...nodeMemberAssociationPageFragment
        }
        country_members: allNodeMemberAssociationPage(
            filter: {
                relationships: {
                    field_tegova_country: { id: { eq: $country_id } }
                }
            }
        ) {
            edges {
                node {
                    ...nodeMemberAssociationPageListFragment
                }
            }
        }
    }
`;
