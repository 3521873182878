import PropTypes from 'prop-types';
import React from 'react';

import './field-links.scss';

/**
 * Component for rendering a list of links.
 */
const FieldLinks = (props) => {
    return (
        <div className="links-field">
            <i className="bi bi-link"></i>

            <ul className="links-field--list">
                {props.links.map((link, i) => {
                    const linkKey = `link-${i}`
                    return (
                        <li key={linkKey}>
                            <a target="_blank" rel="noreferrer" href={link.uri}>
                                {link.uri.replace(/(^\w+:|^)\/\//, '')}
                            </a>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

FieldLinks.propTypes = {
    links: PropTypes.array,
};

export default FieldLinks;
