import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';

import FieldLink from '../../field/FieldLink/FieldLink';

/**
 * Component for rendering a list of member representatives.
 */
const TegovaCountry = (props) => {
    return (
        <div className="tegova-country">
            {props.logo && props.logo.localFile && (
                <Img fluid={props.logo.localFile.childImageSharp.fluid} />
            )}
            <h3>{props.name}</h3>
            {props.description && (
                <div
                    className="description"
                    dangerouslySetInnerHTML={{ __html: props.description }}
                />
            )}
            <ul>
                {props.members.map((member, i) => {
                    return (
                        <li>
                            <FieldLink link={{ uri: member.node.path.alias }}>
                                {member.node.r.association.key}
                            </FieldLink>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

TegovaCountry.propTypes = {
    members: PropTypes.array.isRequired,
    logo: PropTypes.object,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
};

export default TegovaCountry;
