import PropTypes from 'prop-types';
import React from 'react';

/**
 * Component for rendering an address.
 */
const FieldAddress = (props) => {
    return (
        <address>
            {props.line1}
            <br />
            {props.line2}
            {props.line2 && <br />}
            {props.country_code}-{props.postal_code} {props.locality}
        </address>
    );
};

FieldAddress.propTypes = {
    line1: PropTypes.string,
    line2: PropTypes.string,
    country_code: PropTypes.string,
    locality: PropTypes.string,
    postal_code: PropTypes.string,
};

export default FieldAddress;
