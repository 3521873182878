import PropTypes from 'prop-types';
import React from 'react';

import './field-email.scss';

/**
 * Component for rendering an email address link.
 */
const FieldEmail = (props) => {
    return (
        <div className="email-field">
            <i className="bi bi-envelope"></i>
            <a href={'mailto:' + props.email}>{props.email}</a>
        </div>
    );
};

FieldEmail.propTypes = {
    email: PropTypes.string,
};

export default FieldEmail;
