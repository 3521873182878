import PropTypes from 'prop-types';
import React from 'react';

import ParagraphAssocRepresentative from '../../paragraph/ParagraphAssocRepresentative/ParagraphAssocRepresentative';

/**
 * Component for rendering a list of member representatives.
 */
const MemberRepresentatives = (props) => {
    if (props.representatives.length > 0) {
        return (
            <div className="member-representatives">
                <h2>Permanent {props.representatives.length > 1 ? 'representatives' : 'representative'} to TEGOVA</h2>
                <div className="row">
                    {props.representatives.map((representative, i) => {
                        const repKey = `rep-${i}`
                        return (
                            <div key={repKey} className="col-md-6">
                                <ParagraphAssocRepresentative {...representative} />
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
    return <></>
};

MemberRepresentatives.propTypes = {
    representatives: PropTypes.array,
};

export default MemberRepresentatives;
